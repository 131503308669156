// Libraries
import { calculateHistoricalStatistics } from "@/utils/calculateStatistics"; // Adjust path as needed
import { Configuration, GeographyApi } from "@esyoil-gmbh/geography-client";
import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { BasePathType } from "~/mixins/breadcrumb";
import { replaceToUmlauts } from "~/mixins/mixins";
import { decodePlaceName, decodeUrlForPlace } from "~/operations";
import {
  CityLink,
  CommunityLink,
  CountyLink,
  DistrictCity,
  DistrictCityResponse,
  DistrictCityZipcode,
  FetchDistrictCitiesPayload,
  GroupedCommunity,
} from "~/types/Location";
import { PartnerType } from "~/types/Partner";

dayjs.extend(utc);
dayjs.extend(duration);

const config = new Configuration({
  basePath: process.env.BACKBONE_ESYOIL,
});
const api = new GeographyApi(config);

@Module({
  name: "federalstates",
  namespaced: true,
  stateFactory: true,
})
export default class Federalstates extends VuexModule {
  stateAssetsPrice = {
    name: "",
    currentPrice: {
      value: 0,
      changedAtUnix: "",
    },
    lastPrice: {
      value: 0,
      changedAtUnix: "",
    },
    relativeChange: 0,
  };
  city = {
    name: "",
    zipcode: null,
    district: null,
  };
  districtCities = [];
  districtCityZipcodes = [];
  statesOilPriceHistory = {
    reletadUnits: "€ / 100 L",
    labels: [],
    sateOilPriceData: [],
    nationalOiPriceData: [],
    countyOiPriceData: [],
  };

  pelletsAvailableStates = [];

  countyNutsCode = null;
  countyName = null;

  countyYearStatistics = {
    highest: {
      fourWeeks: { price: 0, date: "" },
      threeMonths: { price: 0, date: "" },
      oneYear: { price: 0, date: "" },
    },
    lowest: {
      fourWeeks: { price: Infinity, date: "" },
      threeMonths: { price: Infinity, date: "" },
      oneYear: { price: Infinity, date: "" },
    },
  };

  stateYearStatistics = {
    highest: {
      fourWeeks: { price: 0, date: "" },
      threeMonths: { price: 0, date: "" },
      oneYear: { price: 0, date: "" },
    },
    lowest: {
      fourWeeks: { price: Infinity, date: "" },
      threeMonths: { price: Infinity, date: "" },
      oneYear: { price: Infinity, date: "" },
    },
  };

  stateArticle = {
    imageUrl: "",
    intro: [],
    content: [],
  };
  historyTableData = [];
  topStateUserComments = [];
  topStatePartners = [];
  countyLinks: CountyLink[] = [];
  cityLinks: CityLink[][] = [];
  communityLinks: CommunityLink[] = [];
  bigCityLinks: CityLink[][] = [];
  topStateCities = [];
  historicalPriceData = {};
  cityPriceHistory = {
    chart: {
      reletadUnits: "€ / 100 L",
      labels: [],
      data: [],
    },
  };
  otherZipcodes: string[] = [];

  cityRequestData = {
    zipcode: "",
  };

  @Mutation
  clearCurrentStateAssetsPrice() {
    this.stateAssetsPrice.name = "";
    this.stateAssetsPrice.currentPrice.value = 0;
    this.stateAssetsPrice.currentPrice.changedAtUnix = "";
    this.stateAssetsPrice.lastPrice.value = 0;
    this.stateAssetsPrice.lastPrice.changedAtUnix = "";
    this.stateAssetsPrice.relativeChange = 0;
  }

  @Mutation
  setCurrentStateAssetsPrice(assetsData) {
    this.stateAssetsPrice.name = "Heizöl";
    this.stateAssetsPrice.currentPrice.value = assetsData.currentPrice.value;
    this.stateAssetsPrice.currentPrice.changedAtUnix =
      assetsData.currentPrice.modifiedAt;
    this.stateAssetsPrice.lastPrice.value = assetsData.lastPrice.value;
    this.stateAssetsPrice.lastPrice.changedAtUnix =
      assetsData.lastPrice.modifiedAt;
    this.stateAssetsPrice.relativeChange = assetsData.relativeChange.toFixed(2);
  }

  @Mutation
  clearCountyLinks() {
    this.countyLinks = [];
  }

  @Mutation
  setCountyLinks(assetsData) {
    assetsData.forEach((item) => {
      this.countyLinks.push(item);
    });
  }

  @Mutation
  setCommunityLinks(assetsData) {
    assetsData.forEach((item) => {
      this.communityLinks.push(item);
    });
  }

  @Mutation
  clearCommunityLinks() {
    this.communityLinks = [];
  }

  @Mutation
  clearCityLinks() {
    this.cityLinks = [];
  }

  @Mutation
  setCityLinks(assetsData) {
    for (let i = 0; i < 32; i++) {
      this.cityLinks.push([]);
    }
    assetsData.forEach((item) => {
      const SortIndex = item.ORT_NAME[0].toLowerCase().charCodeAt(0) - 97;
      this.cityLinks[SortIndex].push(item);
    });
  }

  @Mutation
  clearTopStateCities() {
    this.topStateCities = [];
  }

  @Mutation
  setTopStateCities(assetsData) {
    this.topStateCities = assetsData;
  }

  @Mutation
  clearStateArticle() {
    this.stateArticle.imageUrl = "";
    this.stateArticle.intro = [];
    this.stateArticle.content = [];
  }

  @Mutation
  setStateArticle(assetsData) {
    this.stateArticle.imageUrl = assetsData.image;
    this.stateArticle.intro = assetsData.intro;
    this.stateArticle.content = assetsData.content;
  }

  @Mutation
  clearStateAssets() {
    this.statesOilPriceHistory.labels = [];
    this.statesOilPriceHistory.sateOilPriceData = [];
  }

  @Mutation
  clearNationalAssets() {
    this.statesOilPriceHistory.nationalOiPriceData = [];
  }

  @Mutation
  clearPartners() {
    this.topStatePartners = [];
  }

  @Mutation
  clearUserComments() {
    this.topStateUserComments = [];
  }

  @Mutation
  restructureAndPusTopPartners(commentsData) {
    commentsData.forEach((item) => {
      this.topStatePartners.push(item);
    });
  }

  @Mutation
  setHistoryTableData(
    tableData: Array<{
      amount: number;
      delta: number;
      priceToday: number;
      priceYesterday: number;
    }>,
  ) {
    this.historyTableData = [];
    tableData.forEach((item) => {
      const tableRowData = [
        `${item.amount.toLocaleString("de-DE", { style: "decimal" })} Liter`,
        item.priceToday.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        }),
        item.priceYesterday.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        }),
        `${item.delta.toLocaleString("de-DE", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} %`,
      ];
      this.historyTableData.push(tableRowData);
    });
  }

  @Mutation
  restructureAndPushTopComments(commentsData) {
    commentsData.forEach((item, i) => {
      const Element = {
        _id: `comment-${i}`,
        customerStatement: item.rop_review_body,
        esyoilStatement: "",
        partnerStatement: "",
        updatedAtByCustomer: item.rop_date_created,
        businessCustomerId: "",
        rating: item.rop_rating_value,
        bcFullName: "",
        customerStatementLength: 0,
      };
      this.topStateUserComments.push(Element);
    });
  }

  @Mutation
  clearAllStateAssets() {
    this.statesOilPriceHistory.labels = [];
    this.statesOilPriceHistory.sateOilPriceData = [];
    this.statesOilPriceHistory.nationalOiPriceData = [];
    this.statesOilPriceHistory.countyOiPriceData = [];
  }

  @Mutation
  clearStateYearAssets() {
    this.stateYearStatistics = {
      highest: {
        fourWeeks: { price: 0, date: "" },
        threeMonths: { price: 0, date: "" },
        oneYear: { price: 0, date: "" },
      },
      lowest: {
        fourWeeks: { price: Infinity, date: "" },
        threeMonths: { price: Infinity, date: "" },
        oneYear: { price: Infinity, date: "" },
      },
    };
  }

  @Mutation
  clearCountyYearAssets() {
    this.countyYearStatistics = {
      highest: {
        fourWeeks: { price: 0, date: "" },
        threeMonths: { price: 0, date: "" },
        oneYear: { price: 0, date: "" },
      },
      lowest: {
        fourWeeks: { price: Infinity, date: "" },
        threeMonths: { price: Infinity, date: "" },
        oneYear: { price: Infinity, date: "" },
      },
    };
  }

  @Mutation
  setCityData(cityData: {
    zipcode: string | number;
    name: string;
    district: string;
  }) {
    this.city.name = cityData.name;
    this.city.zipcode = cityData.zipcode;
    this.city.district = cityData.district;
  }

  @Mutation
  restructureAndPushStateAssets(
    assetsData: {
      priceData: Array<string | number>;
      variant: string;
      addLabels: boolean;
    }[],
  ) {
    assetsData.forEach((assetData) => {
      assetData.priceData.forEach((item) => {
        const Timestamp = dayjs.unix(item[0]).utc().format("DD.MM.YYYY");
        const Price = item[1];
        if (assetData.addLabels === true)
          this.statesOilPriceHistory.labels.push(Timestamp);
        if (assetData.variant === "state")
          this.statesOilPriceHistory.sateOilPriceData.push(Price);
        if (assetData.variant === "nation")
          this.statesOilPriceHistory.nationalOiPriceData.push(Price);
        if (assetData.variant === "county")
          this.statesOilPriceHistory.countyOiPriceData.push(Price);
      });
    });
  }
  @Mutation
  setStateYearAssets(assetsData: []) {
    this.stateYearStatistics = calculateHistoricalStatistics(assetsData) as any;
  }

  @Mutation
  setCountyYearAssets(assetsData: []) {
    this.countyYearStatistics = calculateHistoricalStatistics(
      assetsData,
    ) as any;
  }

  @Mutation
  setDistrictCities(payload: {
    cities: DistrictCity[];
    currentDistrictCity?: string;
    shouldFilter?: boolean;
  }): void {
    if (payload.shouldFilter && payload.currentDistrictCity) {
      this.districtCities = payload.cities.filter(
        (city) => city.name !== payload.currentDistrictCity,
      );
    } else {
      this.districtCities = payload.cities;
    }
  }

  @Mutation
  setDistrictCityOtherZipcodes(districtZipcodes: DistrictCityZipcode[]): void {
    this.districtCityZipcodes = districtZipcodes;
  }

  @Mutation
  setSingleCityZipcode(zipcode: string) {
    this.cityRequestData.zipcode = zipcode;
  }

  @Mutation
  resetCityRequestData() {
    this.cityRequestData = {
      zipcode: "",
    };
  }

  @Mutation
  async setBigCityLinks(assetsData) {
    this.bigCityLinks = [];
    for (let i = 0; i < 32; i++) {
      this.bigCityLinks.push([]);
    }
    assetsData.forEach((item) => {
      item.link = encodeURIComponent(item.link);
      const sortIndex = item.name.toLowerCase().charCodeAt(0) - 97;
      this.bigCityLinks[sortIndex].push(item);
    });
  }

  @Mutation
  async setPelletsAvailableStates(pelletsStates) {
    this.pelletsAvailableStates = pelletsStates;
  }

  @Mutation
  setCountyInfo(payload: { nutsCode: string; name: string }): void {
    this.countyNutsCode = payload.nutsCode;
    this.countyName = payload.name;
  }

  @Mutation
  resetCountyInfo(): void {
    this.countyNutsCode = null;
    this.countyName = null;
  }

  @Action
  async loadNationAndStateData(payload: { months: number; nutsCode: string }) {
    try {
      this.context.commit("clearAllStateAssets");
      const [nation, state] = await Promise.all([
        axios.get(
          `${process.env.API_ESYOIL}${process.env.CHARTS_JAHRESUEBERSICHT}/${payload.months}`,
        ),
        axios.get(
          `${process.env.API_ESYOIL}${process.env.FEDERAL_STATE_CHART}/${payload.nutsCode}/${payload.months}`,
        ),
      ]);

      this.context.commit("restructureAndPushStateAssets", [
        {
          priceData: nation.data.data.data,
          variant: "nation",
          addLabels: false,
        },
        {
          priceData: state.data.data.data,
          variant: "state",
          addLabels: true,
        },
      ]);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async loadNationStateAndCountyData(payload: {
    months: number;
    stateNutsCode: string;
    countyNutsCode: string;
  }) {
    try {
      this.context.commit("clearAllStateAssets");
      const [nation, state, county] = await Promise.all([
        axios.get(
          `${process.env.API_ESYOIL}${process.env.CHARTS_JAHRESUEBERSICHT}/${payload.months}`,
        ),
        axios.get(
          `${process.env.API_ESYOIL}${process.env.FEDERAL_STATE_CHART}/${payload.stateNutsCode}/${payload.months}`,
        ),
        axios.get(
          `${process.env.API_ESYOIL}${process.env.COUNTY_CHART}/${payload.countyNutsCode}/${payload.months}`,
        ),
      ]);

      this.context.commit("restructureAndPushStateAssets", [
        {
          priceData: nation.data.data.data,
          variant: "nation",
          addLabels: false,
        },
        {
          priceData: state.data.data.data,
          variant: "state",
          addLabels: true,
        },
        {
          priceData: county.data.data.data,
          variant: "county",
          addLabels: false,
        },
      ]);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async fetchStateYearData(payload: { nutsCode: string }) {
    try {
      this.context.commit("clearStateYearAssets");

      const state = await axios.get(
        `${process.env.API_ESYOIL}${process.env.FEDERAL_STATE_CHART}/${payload.nutsCode}/12`,
      );
      this.context.commit("setStateYearAssets", state.data.data.data);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async fetchCountyYearData(payload: { countyNutsCode: string }) {
    try {
      this.context.commit("clearCountyYearAssets");
      const county = await axios.get(
        `${process.env.API_ESYOIL}${process.env.COUNTY_CHART}/${payload.countyNutsCode}/12`,
      );

      this.context.commit("setCountyYearAssets", county.data.data.data);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async fetchPelletsAvailableStates() {
    try {
      const res: any = await api.geographyV1StateAllNutsGet("PARTNER_PELLET");
      this.context.commit("setPelletsAvailableStates", res.data);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async fetchCityName(payload: {
    zipcode: string;
    location: string | null;
    basePath: BasePathType;
  }) {
    try {
      const res: any = await api.geographyV1GetPlaceNamesZipcodeGet(
        payload.zipcode,
      );

      if (res.data.length === 0) {
        return;
      }

      if (res.data && res.data.length > 0) {
        const item = payload.location
          ? res.data.find((item) => {
              const lowerName = item.GEMEINDE_NAME.toLowerCase();
              return (
                lowerName ===
                  decodeURIComponent(payload.location.toLowerCase()) ||
                lowerName ===
                  decodePlaceName(payload.location).replaceAll("|", "/") ||
                lowerName ===
                  replaceToUmlauts(payload.location.replaceAll("|", "/")) ||
                replaceToUmlauts(lowerName) ===
                  replaceToUmlauts(payload.location.replaceAll("|", "/"))
              );
            }) || res.data[0]
          : res.data[0];
        this.context.commit("setSingleCityZipcode", payload.zipcode);

        this.context.commit("setCityData", {
          name: item.GEMEINDE_NAME,
          zipcode: payload.zipcode,
          district: item.KREIS_NAME,
        });

        this.context.commit(
          "breadcrumb/setBreadCrumbForCitySite",
          {
            locationInfo: {
              state: item.BUNDESLAND_NAME,
              county: item.KREIS_NAME,
              city: item.GEMEINDE_NAME,
              zipcode: item.POSTLEITZAHL,
              kreisTyp: item.KREIS_TYP,
            },
            basePath: payload.basePath,
          },
          {
            root: true,
          },
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async fetchStateCountyData(payload: {
    stateNutsCode: string;
    countyNutsCode: string;
  }) {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.COUNTY_PRICE_OVERVIEW}/${payload.stateNutsCode}/${payload.countyNutsCode}`,
      );
      this.context.commit("setCurrentStateAssetsPrice", result.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async fetchTopUserComments(stateNutsCode: number) {
    this.context.commit("clearUserComments");
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.FEDERAL_STATE}/${stateNutsCode}/top-user-comments`,
      );
      this.context.commit("restructureAndPushTopComments", result.data.data);
    } catch (err) {
      //
    }
  }

  @Action
  async fetchTopPartners(stateNutsCode: number) {
    this.context.commit("clearPartners");
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.FEDERAL_STATE}/${stateNutsCode}/top-dealers`,
      );

      if (!result.data?.error) {
        this.context.commit("restructureAndPusTopPartners", result.data.data);
      }
    } catch (err) {
      //
    }
  }

  @Action
  async fetchOilAssets(monthsQUantity: number) {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.CHARTS_JAHRESUEBERSICHT}/${monthsQUantity}`,
      );
      this.context.commit("restructureAndPushStateAssets", {
        priceData: result.data.data.data,
        variant: "nation",
      });
    } catch (error) {
      //
    }
  }

  @Action
  async fetchStateArticle(stateName: string) {
    this.context.commit("clearStateArticle");
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.FEDERAL_STATE_ARTICLE}/${stateName}`,
      );
      this.context.commit("setStateArticle", result.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async fetchTopStateCities(stateName: string) {
    this.context.commit("clearTopStateCities");
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.FEDERAL_STATE_TOP_CITIES}/${stateName}`,
      );
      this.context.commit("setTopStateCities", result.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async fetchPelletsTopStateCities(payload: {
    stateName: string;
    partnerType?: PartnerType;
  }) {
    this.context.commit("clearTopStateCities");
    try {
      const result =
        await api.geographyV1StateOtherCitiesCountByStreetsStateNameLimitGet(
          payload.stateName,
          5,
          payload.partnerType,
        );
      this.context.commit("setTopStateCities", result.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async fetchCountyLinks(payload: {
    statesNutsCode: string;
    partnerType?: PartnerType;
  }) {
    this.context.commit("clearCountyLinks");

    let res: any;
    if (payload.partnerType) {
      res = await api.geographyV1RegionNutsStateNutsGet(
        payload.statesNutsCode,
        payload.partnerType,
      );
    } else {
      res = await api.geographyV1RegionNutsStateNutsGet(payload.statesNutsCode);
    }

    const frontRes = res.data.map((item) => {
      return {
        name: item.KREIS_NAME,
        link: decodePlaceName(encodeURIComponent(item.KREIS_NAME)),
        kreisNuts: item.KREIS_NUTSCODE,
      };
    });

    this.context.commit("setCountyLinks", frontRes);
  }

  @Action
  async fetchDistrictCities(
    payload: FetchDistrictCitiesPayload,
  ): Promise<void> {
    try {
      const response = (await api.geographyV1StateStateNutsDistrictCitiesGet(
        payload.stateNutsCode,
        payload.partnerType,
      )) as unknown as AxiosResponse<DistrictCityResponse[]>;

      const mappedCities = response.data.map((item) => ({
        name: item.districtCity,
        ...(payload.includeLinkField && {
          link: decodePlaceName(encodeURIComponent(item.districtCity)),
        }),
      }));

      this.context.commit("setDistrictCities", {
        cities: mappedCities,
        currentDistrictCity: payload.currentDistrictCity,
        shouldFilter: payload.shouldFilter,
      });
    } catch (error) {
      console.error("Error fetching district cities:", error);
    }
  }

  @Action
  async fetchDistrictCityZipcodes(payload: {
    districtCity: string;
    urlPrefix: string;
    partnerType?: PartnerType;
  }): Promise<void> {
    try {
      const res = await api.geographyV1GetOtherZipcodesPlaceZipcodeGet(
        payload.districtCity,
        "",
        payload.partnerType,
      );

      const zipcodes = res.data as unknown as string[];

      const districtCityOtherzipcodes: DistrictCityZipcode[] = zipcodes.map(
        (zipcode) => ({
          name: payload.districtCity,
          link: decodeUrlForPlace(
            `${payload.urlPrefix}-${payload.districtCity}-${zipcode}`,
            payload.urlPrefix as "pelletspreise" | "heizoelpreise",
          ),
          zipcode,
        }),
      );

      this.context.commit(
        "setDistrictCityOtherZipcodes",
        districtCityOtherzipcodes,
      );
    } catch (error) {
      console.error("Error fetching district city zipcodes:", error);
    }
  }

  @Action
  async fetchCommunityLinks(payload: {
    communityNutsCode: string;
    partnerType?: PartnerType;
  }) {
    this.context.commit("clearCommunityLinks");
    try {
      let res: any;
      if (payload.partnerType) {
        res = await api.geographyV1DistrictRegionDistrictNutsGet(
          payload.communityNutsCode,
          payload.partnerType,
        );
      } else {
        res = await api.geographyV1DistrictRegionDistrictNutsGet(
          payload.communityNutsCode,
        );
      }

      // Group municipalities by their name and their postcodes
      const groupedMunicipalities = res.data.reduce((acc, item) => {
        if (!acc[item.GEMEINDE_NAME]) {
          acc[item.GEMEINDE_NAME] = {
            name: item.GEMEINDE_NAME,
            postcodes: [],
          };
        }
        acc[item.GEMEINDE_NAME].postcodes.push(item.POSTLEITZAHL);
        return acc;
      }, {});

      // Create one entry per postcode for each community
      const frontRes = Object.values(groupedMunicipalities)
        .flatMap((municipality: GroupedCommunity) => {
          const linkPrefix = payload.partnerType
            ? "pelletspreise"
            : "heizoelpreise";

          if (municipality.postcodes.length === 1) {
            return [
              {
                name: municipality.name,
                displayName: municipality.name,
                link: decodeUrlForPlace(
                  `${linkPrefix}-${municipality.name}-${municipality.postcodes[0]}`,
                  linkPrefix,
                ),
                zipcode: municipality.postcodes[0],
              },
            ];
          } else {
            return municipality.postcodes.map((zipcode) => ({
              name: municipality.name,
              displayName: `${municipality.name} (${zipcode}) `,
              link: decodeUrlForPlace(
                `${linkPrefix}-${municipality.name}-${zipcode}`,
                linkPrefix,
              ),
              zipcode,
            }));
          }
        })
        .sort(
          (a, b) =>
            a.name.localeCompare(b.name) || a.zipcode.localeCompare(b.zipcode),
        );

      this.context.commit("setCommunityLinks", frontRes);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async fetchBigCityLinks(stateName) {
    const res: any =
      await api.geographyV1StateOtherCitiesCountByStreetsStateNameLimitGet(
        stateName,
        50,
      );
    const frontRes = res.data.map((item) => {
      return {
        link: decodeUrlForPlace(
          `heizoelpreise-${item._id.replace("/", "|")}-${item.POSTLEITZAHL}`,
        ),
        name: item._id,
        zipcode: item.POSTLEITZAHL,
      };
    });
    this.context.commit("setBigCityLinks", frontRes);
  }

  @Action
  async fetchCurrentStateOilPrice(stateNutsCode) {
    this.context.commit("clearCurrentStateAssetsPrice");
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.FEDERAL_STATE}/${stateNutsCode}/priceoverview`,
      );
      this.context.commit("setCurrentStateAssetsPrice", result.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async fetchStateOilPrices(payload: {
    stateNutsCode: string;
    monthsQuantity: string;
  }) {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.FEDERAL_STATE_CHART}/${payload.stateNutsCode}/${payload.monthsQuantity}`,
      );
      this.context.commit("restructureAndPushStateAssets", {
        priceData: result.data.data.data,
        variant: "state",
        addLabels: true,
      });
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async fetchHistoryTableData(zipcode: string) {
    try {
      const result = await axios({
        method: "get",
        url: `${process.env.API_ESYOIL}${process.env.HISTORY_TABLE}/${zipcode}/2000/3000/1000`,
        headers: {
          accept: "*/*",
        },
      });
      this.context.commit("setHistoryTableData", result.data.data);
    } catch (error) {
      console.error(error.response.data);
    }
  }

  @Mutation
  clearHistoricalPriceData() {
    this.cityPriceHistory.chart.labels = [];
    this.cityPriceHistory.chart.data = [];
  }
  @Mutation
  setHistoricalPriceData(historicalPriceData) {
    historicalPriceData.forEach((element) => {
      this.cityPriceHistory.chart.data.push({
        x: element.date,
        y: Number(element.price),
      });
      this.cityPriceHistory.chart.labels.push(
        dayjs(element.date).format("DD.MM.YYYY"),
      );
    });
  }
  @Action
  async fetchHistoricalPriceData(payload: { zipcode: number; months: string }) {
    this.context.commit("clearHistoricalPriceData");
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.ZIPCODE_RELATED_PRICES}/${payload.zipcode}/${payload.months}`,
      );
      this.context.commit("setHistoricalPriceData", result.data.data.series);
    } catch (error) {
      console.error(error);
    }
  }

  @Mutation
  restructurePushPartnersAndRatings(data) {
    this.topStatePartners = data.dealerList.sort(
      (a, b) => b.pre_grade - a.pre_grade,
    );
  }
  @Action
  async fetchPartnersAndRatingyByZipcode(zipcode) {
    const exist: any = await api.geographyV1LocationZipcodeExistGet(zipcode);

    const zip = exist.data ? zipcode : "00000";

    this.context.commit("clearPartners");
    this.context.commit("clearUserComments");
    const result = await axios.get(
      `${process.env.API_ESYOIL}${process.env.PARTNERS_AND_RATIUNGS}/${zip}`,
    );

    this.context.commit("restructurePushPartnersAndRatings", result.data.data);
    this.context.commit(
      "restructureAndPushTopComments",
      result.data.data.userRatings,
    );
  }

  @Mutation
  setOtherZipcodes(zipcodes) {
    this.otherZipcodes = zipcodes;
  }

  @Action
  async fetchCountyIdentData(locationData: {
    stateName: string;
    countyName: string;
    partnerType?: PartnerType;
  }) {
    try {
      const res: any = await api.geographyV1RegionNameStateNameDistrictNameGet(
        locationData.stateName,
        locationData.countyName,
        locationData.partnerType,
      );

      const data = res.data ? res.data : res;

      if (data.meta?.KREIS_NUTSCODE && data.meta?.KREIS_NAME) {
        this.context.commit("setCountyInfo", {
          nutsCode: data.meta.KREIS_NUTSCODE,
          name: data.meta.KREIS_NAME,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async fetchOtherZipcodes(payload: {
    location: string;
    zipcode: string;
    partnerType?: PartnerType;
  }) {
    if (payload.location && payload.zipcode) {
      try {
        const result = (await api.geographyV1GetOtherZipcodesPlaceZipcodeGet(
          payload.location,
          payload.zipcode,
          payload.partnerType,
        )) as unknown as AxiosResponse<string[]>;

        if (result.data) {
          this.context.commit("setOtherZipcodes", result.data);
        }
      } catch (error) {
        console.error(error.response.data);
      }
    }
  }

  @Action
  async fetchCityZipcodes(cityName: string) {
    this.context.commit("resetCityRequestData");
    const res: any = await api.geographyV1GetZipcodesByPlaceNameStringGet(
      cityName,
    );
    if (res.data && res.data.length > 0) {
      const item = cityName
        ? res.data.find(
            (item) =>
              item.GEMEINDE_NAME.toLowerCase() ===
              decodeURIComponent(cityName.toLowerCase()),
          ) || res.data[0]
        : res.data[0];

      this.context.commit("setCityData", {
        name: item.GEMEINDE_NAME,
        zipcode: item._id,
        district: item.KREIS_NAME,
      });
      this.context.commit("setSingleCityZipcode", res.data[0]._id);

      this.context.commit(
        "breadcrumb/setBreadCrumbForCitySite",
        {
          locationInfo: {
            state: item.BUNDESLAND_NAME,
            county: item.KREIS_NAME,
            city: item.GEMEINDE_NAME,
            zipcode: item.POSTLEITZAHL,
          },
        },
        {
          root: true,
        },
      );
    }
  }
}
