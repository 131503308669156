// Libraries
import dayjs from "dayjs";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  createNewsBreadCrumb,
  generateNewsBreadcrumb,
  returnPostDateSegment,
} from "~/mixins/mixins";

import {
  addBaseBreadcrumb,
  addPrefixBreadcrumb,
  BasePathType,
  createCityBreadcrumb,
  createCityStateBreadcrumb,
  createCountyBreadcrumb,
  createKreisfreieStadtBreadcrumb,
  createStateBreadcrumb,
  getBasePathType,
} from "~/mixins/breadcrumb";
import { BreadCrumbDetail } from "~/types/BreadCrumb";

const CITY_STATES = ["Bremen", "Hamburg", "Berlin"];

@Module({
  name: "breadcrumb",
  namespaced: true,
  stateFactory: true,
})
export default class BreadCrumbStore extends VuexModule {
  breadCrumb: BreadCrumbDetail[] = [];
  breadCrumbNews: BreadCrumbDetail[] = [];

  @Mutation
  setBreadCrumbForBundeslandAndCounty({
    breadCrumbData,
    fullPath,
  }: {
    breadCrumbData: BreadCrumbDetail[];
    fullPath: string;
  }): void {
    const basePath = getBasePathType(fullPath);
    this.breadCrumb = addPrefixBreadcrumb(
      [
        ...breadCrumbData.map((item, index) => ({
          ...item,
          lastItem: index === breadCrumbData.length - 1,
        })),
      ],
      basePath,
    );
  }

  @Mutation
  setBreadCrumbForCitySite(payload: {
    locationInfo: {
      city: string;
      state: string;
      county: string;
      sameCity: boolean;
      zipcode: string;
      kreisTyp?: string;
    };
    basePath: BasePathType;
  }): void {
    const { city, state, county, zipcode, kreisTyp } = payload.locationInfo;
    const basePath = payload.basePath;
    const breadcrumbs: BreadCrumbDetail[] = [];

    if (CITY_STATES.includes(state)) {
      breadcrumbs.push(createCityStateBreadcrumb(state, basePath));
    } else {
      if (city !== state) {
        breadcrumbs.push(createStateBreadcrumb(state, basePath));
      }

      if (kreisTyp === "Kreisfreie Stadt") {
        breadcrumbs.push(
          createKreisfreieStadtBreadcrumb(state, city, basePath),
        );
      } else if (county !== city) {
        breadcrumbs.push(createCountyBreadcrumb(state, county, basePath));
      }
    }

    breadcrumbs.push(createCityBreadcrumb(city, zipcode, basePath));
    this.breadCrumb = addBaseBreadcrumb(breadcrumbs, basePath);
  }

  @Mutation
  setGlobalNewsBreadcrumbs(newsInfo) {
    const { path, query, fullPath } = newsInfo;
    const pathParts = path.split("/").filter((part) => part.trim() !== "");
    const breadcrumbs = pathParts
      .map((part, index) => generateNewsBreadcrumb(part, index, pathParts))
      .filter((breadcrumb) => breadcrumb !== null);

    if (query) {
      const month = returnPostDateSegment("month", query.startIsoDate);
      const year = returnPostDateSegment("year", query.startIsoDate);

      const breadCrumb: BreadCrumbDetail = {
        linkUrl: fullPath,
        linkValue: fullPath,
        name: `News-Archiv ${month} ${year}`,
      };

      breadcrumbs.push(breadCrumb);
    }
    this.breadCrumbNews = breadcrumbs;
  }

  @Mutation
  setNewsBreadCrumbForIndividualBlogPost(newsInfo) {
    const { blogPost, routePath } = newsInfo;

    const formatDate = (date: any) => new Date(date).toISOString();
    const inputDate = new Date(blogPost.date);
    const startOfIsoDateString = formatDate(dayjs(inputDate).startOf("month"));
    const endOfIsoDateString = formatDate(dayjs(inputDate).endOf("month"));

    const dateSegments = [
      { linkUrl: `/news`, linkValue: `/news`, name: `News` },
      {
        linkUrl: `/news/archiv`,
        linkValue: `/news/archiv`,
        name: `News-Archiv`,
      },
      {
        linkUrl: `/news/archiv/archive-category?startIsoDate=${startOfIsoDateString}&endIsoDate=${endOfIsoDateString}`,
        linkValue: `/news/archiv/archive-category?startIsoDate=${startOfIsoDateString}&endIsoDate=${endOfIsoDateString}`,
        name: `News-Archiv ${returnPostDateSegment(
          "month",
          startOfIsoDateString,
        )} ${returnPostDateSegment("year", startOfIsoDateString)}`,
      },
      {
        linkUrl: routePath,
        linkValue: routePath,
        name: blogPost.title.rendered,
      },
    ];

    const breadCrumbNews = dateSegments.map((segment) =>
      createNewsBreadCrumb(segment.linkUrl, segment.linkValue, segment.name),
    );

    this.breadCrumbNews = breadCrumbNews;
  }
}
